import pb from "../lib/pocketbase";
import {
  Container,
  Button,
  Alert,
  Row,
  Col,
  Image,
  Badge,
  Form,
} from "react-bootstrap";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

const CheckoutPage = () => {
  let { encryptedId } = useParams();
  const [bookId, setBookId] = useState("");
  const [book, setBook] = useState({});
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [emailConfirm, setEmailConfirm] = useState("");
  const [loading, setLoading] = useState(false);
  const [available, setAvailable] = useState();
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const loadBook = async () => {
    try {
      const tempBook = await pb.collection("book").getOne(bookId, {
        expand: "category, checkout_via_book",
        fields: "*,expand.category.*,expand.checkout_via_book.date_returned, expand.checkout_via_book.book"
      });
      setBook(tempBook);
    } catch (err) {
      navigate("/404");
    }
  };

  const getAvailable = () => {
    if (book.expand?.checkout_via_book !== undefined) {
      const outstanding = book.expand.checkout_via_book.filter(
        (checkout) => checkout.date_returned === ""
      );
      if (outstanding.length !== 0) {
        return false;
      }
    }
    return true;
  };

  async function checkoutBook(firstName, lastName, email, bookId) {
    // const token = generateRandomToken(32);
    const data = {
      book: bookId,
      firstname: firstName,
      lastname: lastName,
      email: email,
      date_checkedout: getTimeStamp(),
    };
    setLoading(true);
    const checkout = await pb
      .collection("checkout")
      .create(data)
      .then((res) => {
        navigate(`/records/${bookId}`, {
          state: { message: "Book successfully checked out! Thank you for using the library! A reciept will be arriving in your inbox shortly." },
        });
      })
      .catch((err) => {
        if (err.data["code"] === 400) {
          console.log("Failed to checkout book, please contact admin");
        }
      });
    setLoading(false);
  }

  async function checkinBook(return_datetime) {
    setLoading(true);
    const checkout = await pb.collection('checkout').getFirstListItem(`book="${bookId}" && date_returned=""`,
      {
        fields: "id, date_returned, book"
      }
    )
    const checkin = await pb
      .collection("checkout")
      .update(checkout.id, {
        date_returned: getTimeStamp()
      })
      .then((res) => {
        navigate(`/records/${bookId}`, {
          state: { message: "Book successfully checked in." },
        });
      })
      .catch((err) => {
        if (err.data["code"] === 400) {
          console.log("Failed to checkin book");
        }
      });
    setLoading(false);
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    if (available === true) {
      console.log(email)
      console.log(emailConfirm)
      if (email === emailConfirm){
        checkoutBook(firstName, lastName, email, bookId);
        loadBook();
      } else{
        setMessage("Emails do not match!")
      }

    } else {
      setMessage("This book has already been checked out!");
    }
  };

  const handleCheckin = (event) => {
    event.preventDefault();
    if (available === false) {
      checkinBook();
      loadBook();
    } else {
      setMessage("This book has NOT been checked out!");
    }
  }

  function decrypt(encryptedId) {
    return encryptedId.replace(/[a-zA-Z]/g, function(c) {
      const offset = c <= 'Z' ? 65 : 97;
      return String.fromCharCode(((c.charCodeAt(0) - offset + 13) % 26) + offset);
    });
  };
    

  useEffect(() => {
    try{
      const decryptedId = decrypt(encryptedId)
      console.log(decryptedId)
      if (decryptedId){
        setBookId(decryptedId);
      } else {
        navigate("/404")
      }
    } catch (err) {
      navigate("/404")
    }

  }, [encryptedId]);

  useEffect(() => {
    if (bookId) {
      loadBook()
      window.history.replaceState(null, "", `/checkout/${bookId}`)
    }

  }, [bookId])

  useEffect(() => {
    if (loading === false){
      setAvailable(getAvailable());
    }

  }, [book]);

  const getTimeStamp = () => {
    const myDateTime = new Date();
    const year = myDateTime.getUTCFullYear();
    const month = String(myDateTime.getUTCMonth() + 1).padStart(2, "0"); // Note: months are zero-based
    const day = String(myDateTime.getUTCDate()).padStart(2, "0");
    const hours = String(myDateTime.getUTCHours()).padStart(2, "0");
    const minutes = String(myDateTime.getUTCMinutes()).padStart(2, "0");
    const seconds = String(myDateTime.getUTCSeconds()).padStart(2, "0");
    const milliseconds = String(myDateTime.getUTCMilliseconds()).padStart(
      3,
      "0"
    );
    const customDateString = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}Z`;

    return customDateString;
  };

  return (
    <Container>
      <h1 className="text-center">Book Checkout</h1>
      <Row className="justify-content-center">
        <Col md={10} lg={8} xl={6}>
          <Row className="">
            <Col xs={3} md={4} className="">
              <Image
                src={(book.coverimg) ? (pb.files.getUrl(book, book.coverimg, {'thumb': '0x200'})): (book.imgurl? (book.imgurl): `https://placehold.co/150x200?text=${book.title}`)}
                rounded
              />
            </Col>
            <Col xs={12} md={8}>
              <h5 className="">{book.title}</h5>
              <h6>{book.subtitle}</h6>
              <p>
                By <b>{book.author}</b>
              </p>
              {available ? (
                <Badge bg="success">Available</Badge>
              ) : (
                <Badge bg="danger">Checked Out</Badge>
              )}
              <br></br>


            </Col>
          </Row>
        </Col>

        <Col lg={9} xl={7} className="mx-auto">
          <hr />
        </Col>

        <Col md={10} lg={8} xl={6}>
          <h2 className="text-center">Checkout Form</h2>

          {!available && (
            <Alert variant={"warning"}>Book has already been checked out</Alert>
          )}

          {available && 
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>First Name*</Form.Label>
              <Form.Control
                type="text"
                required
                value={firstName}
                disabled={!available || loading}
                onChange={(event) => {
                  setFirstName(event.target.value);
                }}
                placeholder="Enter first name"
              />
              <Form.Text className="text-muted"></Form.Text>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Last Name*</Form.Label>
              <Form.Control
                required
                type="text"
                value={lastName}
                disabled={!available || loading}
                onChange={(event) => {
                  setLastName(event.target.value);
                }}
                placeholder="Enter last name"
              />
              <Form.Text className="text-muted"></Form.Text>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Email*</Form.Label>
              <Form.Control
                required
                type="email"
                value={email}
                disabled={!available || loading}
                onChange={(event) => {
                  setEmail(event.target.value);
                }}
                placeholder="Enter email"
              />

            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Confirm Email*</Form.Label>
              <Form.Control
                required
                type="email-confirm"
                value={emailConfirm}
                disabled={!available || loading}
                onChange={(event) => {
                  setEmailConfirm(event.target.value);
                }}
                placeholder="Confirm your email"
              />
                            <Form.Text className="text-muted">
                We'll never share your email with anyone else.
              </Form.Text>
            </Form.Group>
            {message && <Alert variant={"danger"}>{message}</Alert>}

            <Button variant="primary" type="submit" disabled={!available || loading}>
              {loading ? "Checking out" : "Checkout"}
            </Button>
          </Form>
          }


          {pb.authStore.isValid && !available && (
                <Button variant="secondary" type="submit" disabled={available} onClick={handleCheckin}>
                  {loading ? "Checking in" : "Checkin"}
                </Button>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default CheckoutPage;
