import PocketBase from "pocketbase";

const pb = new PocketBase(process.env.REACT_APP_PB_URL);
const categoryLUT = await pb.collection("Category").getFullList();

export function getCategoryId(categoryCode) {
  if (!Array.isArray(categoryLUT)) {
    console.error("Invalid or undefined categoryLUT");
    return ""; // or any other default value or appropriate action
  }

  const category = categoryLUT.filter((category) => {
    return category.code?.toLowerCase() === categoryCode?.toLowerCase();
  });
  if (category.length > 0) {
    const categoryId = category[0]?.id;
    return categoryId;
  } else {
    // console.error(`Category with code '${categoryCode}' not found`);
    return null; // or any other default value or appropriate action
  }
}

export const getAvailable = (book) => {
  if (book.expand?.checkout_via_book !== undefined) {
    const outstanding = book.expand.checkout_via_book.filter(
      (checkout) => checkout.date_returned === "",
    );
    console.log(outstanding)
    if (outstanding.length !== 0) {
      return false;
    }
  }
  return true;
};

export default pb;
