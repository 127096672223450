import { Container, Col, Row, Accordion } from "react-bootstrap";
const AboutPage = () => {
  return (
    <Container>
      <h1 className="text-center">About</h1>
      <Row className="justify-content-center">
        <Col sm={12} md={10} lg={8}>
          <p>
            Welcome to the CGCSE library! We are glad you can join us. There are
            many greats books here that you can borrow and read. Right now you
            can search our catalogue of books in the home page.
          </p>

          <h3 className="text-center">Frequently Asked Questions</h3>

          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                What is the purpose and goal of the CGCS (English congregation)
                Library Ministry?
              </Accordion.Header>
              <Accordion.Body>
                To encourage reading of books that can help CGCS to grow in
                knowledge and love of God, His people, and His world.
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="1">
              <Accordion.Header>How do I search for books?</Accordion.Header>
              <Accordion.Body>
                It’s easy to choose your next great read! In the library, books
                are organized by category and then alphabetically by author’s
                last name. You can also browse the{" "}
                <a href="https://library.cgcse.ca">
                  library’s online catalogue
                </a>
                .
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="2">
              <Accordion.Header>
                How long can I borrow books for?
              </Accordion.Header>
              <Accordion.Body>
                One month. If you need to extend your borrow time, please
                contact the librarian at{" "}
                <a href="mailto:library@chinesegospelchurch.net">
                  library@chinesegospelchurch.net
                </a>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="3">
              <Accordion.Header>
                How many books can I borrow at one time?
              </Accordion.Header>
              <Accordion.Body>
                There is no maximum number. To encourage the circulation of
                books amongst all library users, we recommend{" "}
                <b>no more than 5 books</b> at one time.
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="4">
              <Accordion.Header>How do I borrow books?</Accordion.Header>
              <Accordion.Body>
                <p>Great, you found a book! To borrow: </p>
                <ol>
                  <li>
                    Scan the QR code (top-left corner of the book cover) and
                    click the link.
                  </li>
                  <li>Fill in your name, email, and click “Checkout” </li>
                  <li>
                    See the due date of your book in the confirmation email
                  </li>
                </ol>
                <p>
                  Note: If you do not have a phone, you may fill out the CGCS
                  Library Checkout Sheet by the library’s message board inside
                  the library.
                </p>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="5">
              <Accordion.Header>How do I return books?</Accordion.Header>
              <Accordion.Body>
                <p>
                  Did you enjoy your read? To return, please put the book(s) on
                  the library cart in the return section. The librarian will
                  process your return(s) and send you a confirmation email upon
                  completion in a week.
                </p>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="6">
              <Accordion.Header>
                Can I donate books to the library?
              </Accordion.Header>
              <Accordion.Body>
                <p>
                  Yes! Thank you for supporting the Library Ministry and
                  encouraging others to read! Please put all donated books (new
                  or used) in the donation section of the library cart.
                </p>
                <p>
                  Note: Suitable books that meet the criteria according to the
                  CGCS Library Ministry’s purpose and goals will be added to the
                  collection by the librarians.
                </p>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="7">
              <Accordion.Header>
                How can I support the Library Ministry?
              </Accordion.Header>
              <Accordion.Body>
                <p>
                  Thank you for your willingness to support! You can support the
                  Library Ministry through:
                </p>
                <ul>
                  <li>your prayers</li>
                  <li>
                    reading and participating in church-wide reading initiatives
                  </li>
                  <li>
                    encouraging others to read more and use the CGCS libary
                  </li>
                  <li>
                    your service - join the team of librarians, and share your
                    spiritual gifts
                  </li>
                  <li>your book donations</li>
                  <li>
                    your feedback / suggestion and contact the librarians at{" "}
                    <a href="mailto:library@chinesegospelchurch.net">
                      library@chinesegospelchurch.net
                    </a>{" "}
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="8">
              <Accordion.Header>How do I scan a QR code?</Accordion.Header>
              <Accordion.Body>
                <ol>
                  <li>Open your phone's camera app</li>
                  <li>Point your phone's camera at the QR code</li>
                  <li>
                    Wait for a banner, notification, or link to appear on your
                    screen
                  </li>
                  <li>Tap to open the banner, notification, or link.</li>
                </ol>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Col>
        {/*
      <h2 className="text-center">Special Thanks</h2>
      It takes a lot of work to categorize, label, and catalogue these books.
      Special thanks to the individuals below who helped out with the library.
      <h4>Labeling</h4>
      <ul>
        <li>Joshua Lok</li>
        <li>Peter Luo</li>
        <li>Sonney Wu</li>
        <li>Thomas Lee</li>
        <li>Zachary Lee</li>
        <li>Liana Lee</li>
        <li>Tony Hong</li>
        <li>Esther Lok</li>
      </ul>
      <h4>Categorizing</h4>
      <ul>
        <li>Esther Lok</li>
        <li>Dunstang Zhang</li>
        <li>Pastor Tim Grieves</li>
      </ul>
      */}
      </Row>
    </Container>
  );
};

export default AboutPage;
