import { useEffect, useState } from "react";
import { Container, Col, Row, Image, Badge, Alert, Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import pb from "../lib/pocketbase";
import { useNavigate, useLocation, Link } from "react-router-dom";

const RecordPage = () => {
  let { bookId } = useParams();
  const [book, setBook] = useState({});
  const navigate = useNavigate();
  const location  = useLocation();
  const [message, setMessage] = useState(location.state?.message || null);

  const loadBook = async () => {
    try {      
      const tempBook = await pb.collection("book").getOne(bookId, {
        expand: "category, checkout_via_book",
        fields: "*,expand.category.*, expand.checkout_via_book.date_returned, expand.checkout_via_book.book",
      });
      setBook(tempBook);
    } catch (err) {
      navigate("/404");
    }
  };

  const getAvailable = () => {
    console.log(book)
    if (book.expand?.checkout_via_book !== undefined) {
      const outstanding = book.expand.checkout_via_book.filter(
        (checkout) => checkout.date_returned === ""
      );
      if (outstanding.length !== 0) {
        return false;
      }
    }
    return true;
  };

  function decrypt(encryptedId) {
    return encryptedId.replace(/[a-zA-Z]/g, function(c) {
      const offset = c <= 'Z' ? 65 : 97;
      return String.fromCharCode(((c.charCodeAt(0) - offset + 13) % 26) + offset);
    });
  };;

  useEffect(() => {
    loadBook();
  }, []);

  return (
    <Container>
      <Row className="justify-content-center">
        <Col md={10} lg={8} xl={6}>
          <Row className="">
            <Col xs={3} md={4} className="">
              <Image
                src={(book.coverimg) ? (pb.files.getUrl(book, book.coverimg, {'thumb': '0x200'})): (book.imgurl? (book.imgurl): `https://placehold.co/150x200?text=${book.title}`)}
                rounded
              />
            </Col>
            <Col xs={12} md={8}>
              <h5 className="">{book.title}</h5>
              <h6>{book.subtitle}</h6>
              <p>
                By <b>{book.author}</b>
              </p>
              {getAvailable() ? (
                <Badge bg="success">Available</Badge>
              ) : (
                <Badge bg="danger">Checked Out</Badge>
              )}
              <p>{book.description}</p>

              {pb.authStore.isValid &&  (
                <Button as={Link} to={`/checkout/${decrypt(bookId)}`} variant="secondary" type="submit" >
                  Checkout
                </Button>
              )}
              

            </Col>
          </Row>
        </Col>
      </Row>

      <Col lg={9} xl={7} className="mx-auto">
        <hr />
      </Col>



      <Row className="mt-4 justify-content-center">
        <Col md={10} lg={8} xl={6} className="mx-auto">
        {message && <Alert variant={"success"}>{message}</Alert>}
          <Row>
            <Col xs={3} md={4}>
              <h5>Details</h5>
            </Col>
            <Col xs={12} md={8}>
              <h6>Category</h6>
              <p>{book.expand?.category.name}</p>
              {book.publisher && (
                <>
                  <h6>Publication</h6>
                  <p>
                    {book.publisher}{" "}
                    {book.publishedDate &&
                      `, ${book.publishedDate.slice(0, 4)}`}
                  </p>
                </>
              )}
              {book.isbn && (
                <>
                  <h6>ISBN</h6>
                  <p>{book.isbn}</p>
                </>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default RecordPage;
