import {
  Form,
  Button,
  Alert,
  Container,
  Row,
  Col,
  Stack,
} from "react-bootstrap";
import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import pb from "../lib/pocketbase";
import useLogin from "../hooks/useLogin";

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { login, isLoading, message } = useLogin();
  const navigate = useNavigate();

  async function handleSubmit(event) {
    event.preventDefault();
    await login(email, password);
    if (pb.authStore.isValid) {
      navigate("/");
    }
  }

  return (
    <Container>
      <h1 className="text-center">Libarian Login</h1>
      <Row className="justify-content-center">
        <Col xs={10} md={8} lg={6}>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="email">
              <Form.Label>Email</Form.Label>
              <Form.Control
                onSubmit={handleSubmit}
                autoComplete="email"
                type="email"
                value={email}
                onChange={(event) => {
                  setEmail(event.target.value);
                }}
                placeholder="Enter email..."
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="password">
              <Form.Label>Password</Form.Label>
              <Form.Control
                autoComplete="current-password"
                type="password"
                value={password}
                onChange={(event) => {
                  setPassword(event.target.value);
                }}
                placeholder="Enter password..."
              />
            </Form.Group>
            {/* <Stack direction="horizontal" gap="3">
              <Link to="/register">Register</Link>

              <Link to="/forgot_password">Forgot your password?</Link>
            </Stack> */}
            {message && <Alert variant={"danger"}>{message}</Alert>}

            <Button
              variant="primary"
              type="submit"
              disabled={isLoading ? true : false}
            >
              {isLoading ? "Logging in..." : "Login"}
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default LoginPage;
